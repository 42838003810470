import React, { memo, useRef } from 'react';

import styled from 'styled-components';

import {
  DailyCounter,
  Icon,
  IconsNames,
  InfoCard,
  InfoCardsTablet,
  LinkArrow,
  SaleOfferCard,
  GiftBlock,
  StickyCounter,
} from 'components/atoms';
import { MainForm, Hits } from 'components/molecules';

import { ColorService, desktop, getFontFamily, tablet } from 'services';
import { PATHS } from 'router/config';

const DayOfferText = memo(() => (
  <SecondaryTitle>
    <YellowText>+5% скидка</YellowText> на курс Бизнес-аналитик в IT
    <Arrow name={IconsNames.arrow_white} width={16} height={16} />
  </SecondaryTitle>
));

const CoursesOnSale = memo(() => (
  <SecondaryTitle>
    Выбрать курс <br /> со скидкой до <br /> <YellowText>25 000 RUB</YellowText>
    <Arrow name={IconsNames.arrow_white} width={16} height={16} />
  </SecondaryTitle>
));

export const Main = memo(() => {
  const formRef = useRef<HTMLDivElement>(null);

  return (
    <Container>
      <Content>
        <MainInfoWrapper>
          <Title>Чёрная пятница</Title>
          <Subtitle>
            Скидки до <br />
            <HighlightedText>- 25 000 RUB</HighlightedText>
          </Subtitle>
          <AdditionalInfo>
            Запишись на IT курсы TeachMeSkills с беспрецендетной скидкой до 25{' '}
            000 RUB с 13 ноября 2023 по 26 ноября 2023
          </AdditionalInfo>

          <GiftBlock />
        </MainInfoWrapper>

        <InfoCardsTabletWrapper>
          <InfoCardsTablet />
        </InfoCardsTabletWrapper>

        <SaleCourses>
          <SaleOfferCard
            path={PATHS.courses}
            title={<CoursesOnSale />}
            infoText="выбрать курс"
            image={IconsNames.common_cover}
          />
          <SaleOfferCard
            path={`${PATHS.courses}?filter=dayOffer`}
            title={<DayOfferText />}
            infoText="предложение дня"
            image={IconsNames.design_cover}
          >
            <AdditionalWrapper>
              <DailyCounter />
              <Text>Предложение действует только сегодня!</Text>
              <Sticker>
                <Icon name={IconsNames.more_benefit} width={150} height={150} />
              </Sticker>
            </AdditionalWrapper>
          </SaleOfferCard>
        </SaleCourses>

        <HitCourses>
          <HitCoursesHead>
            <SecondaryTitle>Хиты продаж</SecondaryTitle>
            <LinkArrow title="Смотреть все" link={PATHS.courses} />
          </HitCoursesHead>

          <Hits />
        </HitCourses>
      </Content>
      <FormWrapper ref={formRef}>
        <MainForm />
        <StyledInfoCard />
      </FormWrapper>

      <StickyCounter formRef={formRef} />
    </Container>
  );
});

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  ${desktop} {
    flex-direction: row;
    gap: 54px;
  }
`;

const Content = styled.div``;

const MainInfoWrapper = styled.div`
  margin: 0 0 64px;

  ${tablet} {
    margin: 18px 0 80px;
  }

  ${desktop} {
    margin: 118px 0 96px;
  }
`;

const InfoCardsTabletWrapper = styled.div`
  display: none;

  ${tablet} {
    display: flex;
    margin-bottom: 80px;
  }

  ${desktop} {
    display: none;
  }
`;

const Title = styled.h1`
  font-family: ${getFontFamily('bold')};
  font-size: 36px;
  line-height: 48px;
  margin-bottom: 8px;

  ${tablet} {
    font-size: 68px;
    line-height: 80px;
    margin-bottom: 16px;
  }
`;

const Subtitle = styled.h2`
  font-family: ${getFontFamily('bold')};
  font-size: 32px;
  line-height: 44px;
  margin-bottom: 24px;

  ${tablet} {
    font-size: 56px;
    line-height: 80px;
    margin-bottom: 32px;
  }
`;

const SecondaryTitle = styled.h3`
  width: 68%;
  font-family: ${getFontFamily('bold')};
  font-size: 24px;
  line-height: 32px;

  ${tablet} {
    width: 100%;
    font-size: 32px;
    line-height: 44px;
  }
`;

const HighlightedText = styled.span`
  color: ${ColorService.YELLOW};
  font-size: 48px;
  line-height: 56px;

  ${tablet} {
    font-size: 80px;
    line-height: 96px;
  }
`;

const AdditionalInfo = styled.p`
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 56px;
  max-width: 322px;

  ${tablet} {
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 64px;
    max-width: 448px;
  }

  ${desktop} {
    max-width: unset;
  }
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  ${desktop} {
    position: -webkit-sticky;
    position: sticky;
    align-self: flex-start;
    top: 54px;
  }
`;

const SaleCourses = styled.div`
  margin-bottom: 64px;
  display: flex;
  flex-direction: column;
  gap: 12px;

  ${tablet} {
    margin-bottom: 80px;
    gap: 20px;
  }
`;

const AdditionalWrapper = styled.div`
  margin-top: auto;
  display: flex;
  flex-direction: column;
  gap: 12px;
  z-index: 1;

  ${tablet} {
    flex-direction: row;
    align-items: center;
  }
`;

const Sticker = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 3;
`;

const YellowText = styled.span`
  color: ${ColorService.YELLOW};
`;

const Text = styled.p`
  max-width: 150px;
  font-size: 12px;
  line-height: 18px;
  color: ${ColorService.GRAY};
`;

const Arrow = styled(Icon)`
  margin-left: 8px;
`;

const HitCourses = styled.div``;

const HitCoursesHead = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 32px;

  ${tablet} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
  }
`;

const StyledInfoCard = styled(InfoCard)`
  display: none;

  ${desktop} {
    display: unset;
  }
`;
